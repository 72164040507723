.Conclusion {
    margin-top: 1em;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Pass-patient-text {
    font-size: 32px;
}

.Pass-text {
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 100px;
    font-weight: 800;
    text-align: center;
}

.Pass-followup-text {
    font-size: 24px;
    font-weight: 600;
    margin-top: 1em;
    margin-bottom: -30px;
}
  
.Fail-text-main {
    padding-top: 0;
    margin-top: -15px;
    margin-bottom: 0px;
    font-size: 72px;
    font-weight: 800;
    text-align: center;
}
  
.Fail-text-small {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 0px;
}