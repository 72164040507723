.Timestamp {
    padding: 36px 0 48px;
    display: flex;
    flex-direction: column;
}
  
.Timestamp-date {
    font-weight: 800;
    font-size: 26px;
}
  
.Timestamp-time {
    font-weight: 800;
    font-size: 26px;
}