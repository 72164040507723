.Content-body {
    font-size: 16px;
    height: 100%;
    color: #000;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.Content-body-centred {
    font-size: 18px;
    color: #000;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.Content-addendum {
    text-align: center;
}

.Content-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    padding-bottom: 1em;
    left: 0;
    bottom: 0;
    width: 100%;
    background:rgb(255, 255, 255);
}

.Content-footer-pass {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    padding-bottom: 1em;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgb(31, 176, 0);
}

.Content-footer-fail {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    padding-top: 0.5em;
    padding-bottom: 1em;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgb(251, 61, 62);
}

.Content-title {
    font-size: 24px;
    font-weight: 900;
    padding-bottom: 0.5em;
}

.Content-subtitle {
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0.25em 0;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    margin-bottom: 0.5em;
}

.Content-disclaimer {
    font-size: 16px;
    font-weight: 700;
}

.Content-disclaimer-list {
    text-align: left;
    font-size: 16px;
    margin-left: -1em;
}

.Content-list {
    padding-bottom: 120px;
}

.Content-question {
    font-size: 28px;
    /* needed? */
    /* font-weight: 600; */
    color: rgb(0, 0, 0);
}

.Content-solo-question {
    padding-top: 3em;
    padding-bottom: 6.5em;
    text-align: center;
}

.Privacy-desc {
    padding: 0.5em 20px 0.5em 20px;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.Default-link {
    color: #0000EE;
    cursor: pointer;
    text-decoration: underline;
}

.Privacy-footer {
    padding-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
}